<template>
  <div class="view" v-loading.fullscreen.body.lock="loading">
    <CompanyName />
    <div class="h-8 flex justify-center items-center grey-color my-5">{{ $t('Tracking Times') }}</div>
    <BaseInput
      :label="$t('Tracking Date')"
      :component="InputDateComponent"
      v-model="selectedDate"
      @change="onChanged"
    />
    <hr />
    <span> {{ getTotalLabel }}</span>
    <TrackedDataFlow
      :isEnableLabel="true"
      :class="`${isDrawing ? 'opacity-0' : ''}`"
      :arrTrackPoints="arrTrackPoints"
      :arrTrackPointLabel="arrUserTrackingCount"
      ref="userTrackingFlow"
    />
  </div>
</template>

<script lang="ts">
import BaseInput from '@/components/BaseInput.vue'
import CompanyName from '@/components/CompanyName.vue'
import TrackedDataFlow from '@/components/detail/TrackedDataFlow.vue'
import InputDate from '@/components/InputDate.vue'
import { FETCH_TRACKING_COUNT } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import { sortTrackingPoints } from '@/utils/helpers'
import { ITrackpoint, TError, TTrackingDataCount } from 'smartbarcode-web-core/src/utils/types/index'
import { cloneDeep } from 'lodash'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
@Options({
  components: {
    TrackedDataFlow,
    CompanyName,
    BaseInput,
  },
  name: 'UserTrackingHistory',
})
export default class UserTrackingHistory extends Vue {
  isDrawing = true
  drawingKey = 0
  loading = false
  selectedDate = ''

  get InputDateComponent() {
    return InputDate
  }

  @Watch('loading')
  onLoadingChanged() {
    if (this.loading) {
      document.documentElement.style.overflow = 'hidden'
      return
    }

    document.documentElement.style.overflow = 'auto'
  }

  get arrTrackPoints() {
    const trackPoints = this.$store.state.project?.details?.trackPoints
    const arrTrackPoints = objectToArray<ITrackpoint>(trackPoints) || []
    const originalArrTracKPoints = cloneDeep(arrTrackPoints)
    sortTrackingPoints(arrTrackPoints)
    return originalArrTracKPoints
  }

  get arrUserTrackingCount() {
    return this.$store.state.profile?.userTrackingDataHistory
  }

  async onChanged() {
    window.scrollTo(0, 0)
    await this.initData()
  }

  get getTotalLabel() {
    const userTrackingHistoryCount: TTrackingDataCount[] = this.$store.state?.profile?.userTrackingDataHistory

    const totalTrackingCount = userTrackingHistoryCount.reduce((acc, curr) => {
      const total = curr.trackingCounts.reduce((acc, curr) => {
        return acc + curr.count
      }, 0)
      return acc + total
    }, 0)
    return this.$t('Total tracking count', { count: totalTrackingCount })
  }

  async initData() {
    try {
      this.loading = true
      const projectId = this.$store.state.project?.details?.id || ''
      await this.$store.dispatch(FETCH_TRACKING_COUNT, {
        projectId,
        date: this.selectedDate,
      })
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  async mounted() {
    try {
      await this.initData()
      await this.reDrawLeaderLine()
    } catch (error) {
      errorHandler(error as TError)
    }
  }

  async reDrawLeaderLine() {
    this.drawingKey++
    try {
      this.loading = true
      await this.$nextTick(async () => {
        if (this.$refs.userTrackingFlow) {
          await queueMicrotask(async () => await this.$refs.userTrackingFlow.drawLeaderLine())
          this.isDrawing = false
          this.loading = false
        }
      })
    } catch (e) {
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.grey-color {
  background: $light-grey;
}
</style>
